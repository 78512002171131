.company-item-container {
  cursor: pointer;
}

.company-item--active {
  background-color: #edf0f5;
}

.company-item-text-container {
  padding: 12px 8px;

  &:hover {
    background-color: #d8d8d8;
  }

  div:nth-child(2) {
    margin-top: 8px;
  }
}

.company-item-title {
  color: #000;
}
