.entry-item {
  position: relative;
  overflow: hidden;

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .ant-spin-dot {
    font-size: 20px;
  }

  .ant-form-item-explain-error {
    display: none;
  }
}

.form-item-errors {
  margin-bottom: 0;
  .ant-form-item-control-input {
    min-height: auto;
  }
}
