.button-doc {
  display: flex;
  align-items: center;

  appearance: none;

  box-shadow: none;
  border: 0px;
  padding: 5px 2px;

  width: 100%;
  background: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &:hover {
    background-color: #d8d8d8;
  }

  path {
    fill: @primary-color;
  }
}

.doc {
  margin: 2px 0;
}

.doc--active {
  background-color: @body-bg-color;
  color: @primary-color;
}

.doc--active.doc--is-duplicated {
  background-color: @warning-color;
}
.doc--is-duplicated {
  background-color: rgba(@warning-color, 0.2);
}

.button-doc__text {
  margin-left: 15px;
}

.button-doc__text-number {
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 58px;
  direction: rtl;
}
