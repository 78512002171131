.ssi-form [class^="ant-"],
.ssi-form [class^="ant-"] input {
  font-size: 12px;
}

.ant-form-item-label > label {
  color: #858796 !important;
}

.ant-input-number {
  width: 100%;
}

.content-form .ant-input-number-input {
  text-align: right !important;
}

.ant-input-number-group-wrapper--error {
  .ant-input-number-disabled,
  .ant-input-number-group-addon {
    border-color: @error-color;
  }

  .ant-input-number-group-addon {
    color: @error-color;
  }
}

.ant-input-number-group-wrapper--success {
  .ant-input-number-disabled,
  .ant-input-number-group-addon {
    border-color: @success-color;
  }

  .ant-input-number-group-addon {
    color: @success-color;
  }
}

.ant-input-number-group-wrapper--error,
.ant-input-number-group-wrapper--success {
  .ant-input-number-disabled {
    border-right-color: transparent;
  }

  .ant-input-number-group-addon {
    background-color: #f5f5f5;
  }
}

.ant-input-status-warning.ant-input,
.ant-input-status-warning.ant-input:hover,
.ant-input-number-status-warning.ant-input-number,
.ant-input-number-status-warning.ant-input-number:hover,
.ant-form-item-has-warning .ant-picker {
  border-color: @warning-color;
  box-shadow: 0 0 0 2px rgb(255 193 7 / 20%);
}

.ant-form-item-has-error {
  .select-component .react-select__control,
  .ant-input[disabled],
  .ant-input-number-disabled,
  .ant-picker.ant-picker-focused,
  .ant-picker {
    border-color: @error-color;
  }
}

.ant-input,
.ant-picker {
  padding: 6px 11px;
  height: 32px;
}

.ant-picker {
  width: 100%;
}

.select-component .react-select__single-value--is-disabled {
  color: @text-color;
}

.select-component .react-select__control--is-disabled {
  background-color: @background-disable;
  border-color: @border-disable;
}

.content-form {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-right: 0;
    width: 0;
    color: transparent;
  }
}

.modal-button {
  position: relative;
  margin-bottom: 5px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  svg:nth-child(2) {
    z-index: 10;
  }
}

.rc-virtual-list-scrollbar-show {
  display: block !important;
}

.ant-form-item-has-warning .ant-form-item-explain-error {
  color: @warning-color;
}

@media (max-height: 750px) {
  .ant-input,
  .ant-picker,
  .ant-input-number {
    padding: 0 11px;
    height: 28px;
  }

  .react-select__control {
    min-height: 28px !important;
    height: 28px;
  }

  .ant-input-number-group,
  .ant-input-number-group-addon {
    height: 28px;
  }

  .ant-btn-icon-only {
    height: 28px;
    width: 28px;
  }

  .ant-form-item-control-input {
    min-height: 28px;
  }
}
