.ant-layout-header * {
  font-size: 14px;
}

.layout-main {
  padding: 0 0.5rem;

  height: calc(100vh - @topbar-height);
  overflow: auto;
}

.display-grid-center {
  display: grid;
  place-items: center;
}

.ant-layout-header {
  background-color: @primary-color;
  color: @white;
  .ant-btn-link {
    color: @white;
  }
}

.ant-layout-header.header .logo {
  max-height: 40px;
}

.ant-layout-content {
  position: relative;
  padding: 20px;
  height: calc(100vh - @header-height);
  overflow-y: auto;
  margin-left: 60px;

  .content-form {
    margin-left: 0;
  }
}
