.entry-item,
.entry-vat,
.subsidiary {
  padding: 5px 0;
  .ant-collapse-content-box,
  .ant-collapse-header {
    padding: 0 !important;
  }

  &:nth-of-type(odd) {
    background-color: @grey;
  }

  .ant-col > .ant-form-item {
    margin: 4px 0;
  }

  .ant-btn {
    margin-top: 4px;
  }

  .ant-collapse-header-text {
    width: calc(100% - 32px);
  }

  .ant-collapse
    > .ant-collapse-item
    .ant-collapse-header-collapsible-only
    .ant-collapse-header-text {
    display: flex;

    .ant-row {
      width: 100%;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    margin-left: 0px;
  }
}

.entry-item.entry-item--details {
  background-color: @secundary-color;
}

.select-component {
  padding: 0;
}

.rc-virtual-list-holder {
  overflow: hidden !important;
}

@media (max-height: 750px) {
  .entry-item .ant-col > .ant-form-item,
  .entry-vat .ant-col > .ant-form-item,
  .subsidiary .ant-col > .ant-form-item {
    margin: 0;
  }

  .ant-collapse-header-text,
  .entry-vat,
  .subsidiary {
    .ant-col {
      height: 28px;
    }
  }

  .entry-item .ant-btn,
  .entry-vat .ant-btn,
  .subsidiary .ant-btn {
    margin: 0;
  }
}
