.btn--info {
  color: @white;
  background-color: @blue;
  border: 1px solid @blue;
}

.button-start {
  border-color: @success-color;
  background: @success-color;
}

.ant-btn > svg + span,
.ant-btn > span + svg {
  margin-left: 8px;
}

.button-actions {
  padding: 0 0.5rem;
}

.ant-btn > svg {
  vertical-align: middle;
}

.react-select__menu-notice {
  text-align: left !important;
}

.react-select__menu-notice .ant-btn {
  padding: 0;
  font-size: 10px;
}
