@header-hight: 75px;

.documents-sidebar .ant-typography {
  margin-bottom: 0;
}

.documents-sidebar .ant-space-item:last-child {
  overflow: auto;
}

.highlight {
  padding: 10px 4px;
  background-color: @highlight-dark;

  .ant-typography {
    color: @white;
  }
}

.company-info {
  padding: 0 0.5rem;
}

.loading-documents {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.list-document-wrapper {
  position: relative;
}
