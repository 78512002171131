.bulk-container,
.bulk-container-without-preview {
  display: grid;
  grid-template-columns: 200px auto 480px;
  grid-template-rows: calc(100vh - 64px);
  grid-template-areas: "documents-sidebar form document-preview";
  overflow: hidden;
  grid-template-columns: 200px auto 0;
}

.bulk-container .documents-sidebar hr {
  margin: 0;
}

@media (min-width: 1200px) {
  .bulk-container {
    grid-template-columns: 200px auto 480px;
  }
}

@media (min-width: 1500px) {
  .bulk-container {
    grid-template-columns: 200px auto 560px;
  }
}

@media (min-width: 1700px) {
  .bulk-container {
    grid-template-columns: 200px auto 660px;
  }
}

@media (min-width: 1900px) {
  .bulk-container {
    grid-template-columns: 200px auto 860px;
  }
}

@media (max-width: 1200px) {
  .bulk-container {
    grid-template-columns: 200px auto 435px;
  }
}

.counter-docs {
  font-size: 1.5em;
  font-weight: 700;
  float: right;
}

@media (max-width: 1200px) {
  .counter-docs {
    font-size: 1em;
  }
}
