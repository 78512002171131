.react-select__option--is-selected .ant-typography {
  color: @white;
}

.ant-form-item-has-warning .select-component .react-select__control {
  border-color: @warning-color;
}

.select-component .react-select__value-container {
  padding: 0 8px;
}

.react-select__control.react-select__control--is-focused {
  box-shadow: 0 0 0 2px rgb(0 133 131 / 20%);
}

.react-select__indicator {
  padding: 4px 0 !important;
}

.react-select__menu-portal .react-select__menu {
  width: 100%;
}

.react-select__group {
  .react-select__group-heading {
    font-size: 100%;
    text-transform: none;
  }
  .react-select__option {
    padding-left: 28px;
  }
}

.react-select__option.option--default-counterpart:not(
    .react-select__option--is-selected
  ) {
  background-color: rgba(@primary-color, 0.15);
}
