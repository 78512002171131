.ant-drawer-bottom > .ant-drawer-content-wrapper {
  right: 10px;
  left: 10px;
}

.drawerDuplicated .ant-drawer-header {
  background-color: @warning-color;
  text-align: center;
}

.drawerDuplicated.ant-drawer-bottom > .ant-drawer-content-wrapper {
  bottom: 50px;
}

.drawerCreditNote .ant-drawer-header {
  background-color: @warning-color;
  text-align: center;
}

.drawerCreditNote.ant-drawer-bottom > .ant-drawer-content-wrapper {
  bottom: 10px;
}
