.ant-layout-content--no-padding {
  padding: 0;
}

.edit-document-vat-container {
  display: grid;
  grid-template-columns: 200px 200px auto 480px;
  grid-template-areas: "company-sidebar documents-sidebar form document-preview";
  height: calc(100vh - @topbar-height);
  overflow: hidden;

  .documents-sidebar {
    padding-left: 0;
  }
}

@media (min-width: 1500px) {
  .edit-document-vat-container {
    grid-template-columns: 200px 260px auto 560px;
  }
}

@media (min-width: 1700px) {
  .edit-document-vat-container {
    grid-template-columns: 200px 260px auto 660px;
  }
}

@media (min-width: 1900px) {
  .edit-document-vat-container {
    grid-template-columns: 200px 260px auto 860px;
  }
}

.company-sidebar {
  background-color: #fff;
  border-right: 1px solid #d5d5d5;
  padding: 8px;
}

.sidebar-item-container {
  padding: 8px 0px;
}

.documents-sidebar {
  background-color: #fff;
  padding: 8px 10px;
  min-width: 200px;
}

.list-container {
  list-style-type: none;
  padding: 0;
  height: calc(100vh - @topbar-height);
  margin-bottom: 0;
  overflow-y: auto;
}

.list-container-with-header {
  list-style-type: none;
  padding: 0;
  height: calc(100vh - @topbar-height - 83px);
  overflow-y: auto;
  margin-bottom: 0;
}

.document-preview-container {
  grid-area: document-preview;
  height: 100%;
}

.form-container {
  grid-area: form;
  height: 100%;
  padding: 64px 16px;
}

.rpv-core__viewer,
.rpv-core__viewer--dark {
  width: auto;
}


@media print {
  .rpv-print__page img {
    width: 612.1237pt;
    height: 792.24746pt;
  }
}
