.form--overlay {
  height: 100%;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.5);
  }
}

.form__loading--wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(@white, 0.4);
}

.form__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(-50%, -50%);
  z-index: 1001;
}
