@header-height: 64px;
@scrollbar-box-shadow-color: rgba(0, 0, 0, 0.2);

* {
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
}

html {
  height: 100%;
}

body {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 1.2rem;
  color: @text-color;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
}

iframe {
  border: none;
}

.neoactivite-alert {
  width: 350px;
}

.neoactivite-alert {
  width: 350px;
}

::-moz-selection {
  /* Code for Firefox */
  color: @white;
  background: @primary-color;
}

::selection {
  color: @white;
  background: @primary-color;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.header-col-align-right {
  text-align: right;
}

.header-col-align-center {
  text-align: center;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: @white;
  color: @primary-color;
}

.ant-space-vertical {
  width: 100%;
}
