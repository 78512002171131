@import "~antd/dist/antd";
@import "~@newrai/ant-design/lib/styles/main";
@import "~@newrai/documents-preview/lib/styles/index.less";

@import "./_variables.less";
@import "./_main.less";
@import "./_utils.less";
@import "./components/_layout.less";
@import "./components/_form-loading.less";
@import "./components/_button.less";
@import "./components/_sidebar.less";
@import "./components/_document-list.less";
@import "./components/_forms.less";
@import "./components/_edit-document-vat.less";
@import "./components/_company-item.less";
@import "./components/_bulk-page.less";
@import "./components/_react-select.less";
@import "./components/_form-list.less";
@import "./components/_entry-vats.less";
@import "./components/_entry-items.less";
@import "./components/_button.less";
@import "./components/_avatar.less";
@import "./components/_table.less";
@import "./components/_drawer.less";

// TODO: move to package
.rpv-core__viewer {
  height: 100% !important;
}
